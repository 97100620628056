import { addErrorHandler, getAppStatus, registerApplication, start } from "single-spa";
import { constructApplications, constructLayoutEngine, constructRoutes } from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import error from "./errorPage.html";
import axios from "axios";

axios.defaults.withCredentials = true;

var interval;
var intervalSetted = false;
var loggedIn = false;
var dataLoading = false;

var currentLanguage = window.location.pathname.split("/")[1];
console.log("currentLanguage: " + currentLanguage);

var redirectUrl = encodeURIComponent(window.location.href); //.replace(/^https?:/, ""));
console.log("redirectUrl (encoded): " + redirectUrl);

window.addEventListener("onLogin", () => {
    console.log("ROOT SPA -> LOGIN");
    window.location.href = `${window.location.origin}/private?redirect_uri=${redirectUrl}`;
});
window.addEventListener("onLogout", () => {
    console.log("ROOT SPA -> LOGOUT");
    window.location.href = `${window.location.origin}/applogout`;
});
window.addEventListener("onRegister", () => {
    console.log("ROOT SPA -> REGISTER");
    window.location.href = `${window.sspaStore.envParams["komis.vue.keycloak.registerUrl"]}&redirect_uri=${redirectUrl}`;
});
window.addEventListener("onChangeLanguage", (e) => {
    console.log("ROOT SPA -> CHANGE_LANGUAGE", e.detail);
    if (window.sspaStore?.loggedUser?.authenticated) setUserSettings(e.detail);
    else redirectToNewLanguage(e.detail);
});

function loadData() {
    // GET ENV PARAMS
    getEnvParams();
}

function getEnvParams() {
    axios
        .get(`/rest/komis/env`)
        .then((response) => {
            //console.log(response.data);
            console.log("ROOT -> saveEnvParams", response.data);
            window.sspaStore.saveEnvParams(response.data);

            getLoggedUser();
            getNetgrifUrls();
        })
        .catch((error) => {
            console.error(error);
        });
}

function getLoggedUser(noSave) {
    axios
        .get(`/rest/komis/loggedUser`)
        .then((response) => {
            //console.log(response);
            if (response.data) {
                if (!noSave) {
                    const loggedUserObj = { authenticated: true, ...response.data };
                    console.log("ROOT -> saveLoggedUser", loggedUserObj);
                    window.sspaStore.saveLoggedUser(loggedUserObj);
                    loggedIn = true;

                    getUserSettings();
                }

                // TOKEN CHECK
                if (!intervalSetted) {
                    console.log("interval started");
                    interval = setInterval(() => {
                        intervalSetted = true;
                        console.log("checking if the user is logged in");
                        getLoggedUser(true);
                    }, 60000);
                }
            } else {
                notAuthenticated();
            }
        })
        .catch((error) => {
            console.error(error);
            notAuthenticated();
        });
}

function getNetgrifUrls() {
    if (window.sspaStore?.envParams["komis.vue.netgrif.forms.url"]) {
        axios
            .get(window.sspaStore.envParams["komis.vue.netgrif.forms.url"], { withCredentials: false })
            .then((response) => {
                console.log("ROOT -> saveNetgrifUrls", response.data.netgrif_forms);
                window.sspaStore.saveNetgrifUrls(response.data.netgrif_forms);
            })
            .catch((error) => {
                console.error(error);
            });
    }
}

function getUserSettings() {
    if (window.sspaStore?.envParams["komis.vue.pp.api.rest.url"] && window.sspaStore?.loggedUser) {
        axios
            .get(
                `${window.sspaStore.envParams["komis.vue.pp.api.rest.url"]}/rest/public/v1/users/${window.sspaStore.loggedUser.userId}`,
            )
            .then((response) => {
                //console.log(response);
                if (response.data && currentLanguage !== response.data.lang) redirectToNewLanguage(response.data.lang);
                else setUserSettings();
            })
            .catch((error) => {
                console.error(error);
            });
    }
}

function setUserSettings(redirectLanguage) {
    const body = {
        firstName: window.sspaStore.loggedUser.firstName,
        lastName: window.sspaStore.loggedUser.lastName,
        email: window.sspaStore.loggedUser.userEmail,
        lang: redirectLanguage || currentLanguage,
    };

    axios
        .put(
            `${window.sspaStore.envParams["komis.vue.pp.api.rest.url"]}/rest/public/v1/users/${window.sspaStore.loggedUser.userId}`,
            body,
        )
        .then((response) => {
            //console.log(response);
            // Register user to SCIDAP on his first access into SCIDAP module
            if (response.data.firstLogin && window.sspaStore.envParams["komis.vue.modul_instance"] === "scidap") {
                let config = {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                };
                let data = {
                    email: response.data.user.email,
                    password: generateString(40),
                };
                axios
                    .put(`${window.sspaStore.envParams["komis.vue.config.invenio.api.url"]}/register`, data, config)
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }

            if (redirectLanguage) {
                redirectToNewLanguage(redirectLanguage);
            } else if (
                response.data.firstLogin &&
                !window.location.pathname.includes("/profil-pouzivatela/nastavenia/prve-prihlasenie")
            ) {
                // TODO: redirect_uri netreba :)
                // window.location.href = `${window.location.origin}/${currentLanguage}/profil-pouzivatela/nastavenia/prve-prihlasenie?redirect_uri=${redirectUrl}`;
                window.location.href = `${window.location.origin}/${currentLanguage}/profil-pouzivatela/nastavenia/prve-prihlasenie`;
            }
        })
        .catch((error) => {
            console.error(error);
        });
}

function generateString(length) {
    var chars = "abcdefghijklmnopqrstuvwxyzľščťžýáíéÉÁŃÓÚABCDEFGHIJKLMNOPQRSTUVWXYZ012345678@&";
    var charLength = chars.length;
    var result = "";
    for (var i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
}

function redirectToNewLanguage(newLanguage) {
    if (currentLanguage !== "admin" && currentLanguage !== "showdoc.do") {
        const newHref = window.location.href.replace(`/${currentLanguage}/`, `/${newLanguage}/`);
        window.location.href = newHref;
    }
}

function notAuthenticated() {
    console.log("Authenticated: FALSE");
    const loggedUserObj = { authenticated: false };
    console.log("ROOT -> saveLoggedUser", loggedUserObj);
    window.sspaStore.saveLoggedUser(loggedUserObj);

    if (loggedIn) {
        console.log("the user has been logged out");

        let translation = {
            text: "Boli ste odhlásený.",
            linkText: "Prihlásenie",
        };
        if (currentLanguage == "en") {
            translation.text = "You have been logged out.";
            translation.linkText = "Log in";
        }

        const link = `${window.location.origin}/private?redirect_uri=${redirectUrl}`;

        document.getElementById("infoPage").innerHTML = `
            <div class="w-100 p-4 bg-white text-center" style="
                position: fixed; top: 0; left: 0; z-index: 999; border-top: 4px solid #A90003;
            ">
                <p class="mb-0">
                    ${translation.text}
                    <a style="color: #E10006;" href="${link}">${translation.linkText}</a>
                </p>
            </div>
        `;
    }
    loggedIn = false;

    clearInterval(interval);
    intervalSetted = false;
}

const data = {
    errors: {
        notFoundError: error,
    },
    props: {
        //
    },
};

const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
    routes,
    loadApp({ name }) {
        return System.import(name);
    },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

addErrorHandler((err) => {
    //console.error("error", err);
    //console.error("status", getAppStatus(err.appOrParcelName));
    document.getElementById("infoPage").innerHTML = `
    <div class="error-info">
        <h1>Aplikácia je nedostupná, prosím kontaktujte
            <a href="https://support.interway.sk/" target="_blank">Helpdesk</a>.
        </h1>
    </div>
    <div class="error-message">
        <h3>${getAppStatus(err.appOrParcelName)}</h3>
        <p>${err}</p>
    </div>`;
});

window.addEventListener("single-spa:before-first-mount", (evt) => {
    console.log(evt.type);
    loadData();
    dataLoading = true;
});

window.addEventListener("single-spa:before-routing-event", (evt) => {
    console.log(evt.type);
});

window.addEventListener("single-spa:before-mount-routing-event", (evt) => {
    console.log(evt.type);
});

window.addEventListener("single-spa:routing-event", (evt) => {
    console.log(evt.type);
    if (!dataLoading) {
        loadData();
        dataLoading = true;
    }
    window.dispatchEvent(new Event("onSingleSpaRouting"));
});

applications.forEach(registerApplication);
layoutEngine.activate();
start();
